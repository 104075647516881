export const parseUtm = () => {
  const self = window.location.search.toString();
  const querystring = self.split('?');

  if (querystring.length > 1) {
    const pairs = querystring[1].split('&');
    pairs?.forEach((pair) => {
      const keyval = pair.split('=');
      if (keyval[0].startsWith('utm_') && sessionStorage.getItem(keyval[0]) === null) {
        sessionStorage.setItem(keyval[0], decodeURIComponent(keyval[1]));
      }
    });
  }
};

export const getExistingUtmTags = () => {
  const existingUtmTags = {};

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key.startsWith('utm_')) {
      existingUtmTags[key] = sessionStorage.getItem(key);
    }
  }

  return existingUtmTags;
};
