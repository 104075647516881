export class ModalEntity {
  id;

  component;

  order = 0;

  opened = false;

  preventEasyClose = false;

  props = {};

  onOpen;

  onClose;

  constructor(props) {
    this.id = props.id;
    this.preventEasyClose = props.preventEasyClose;
    this.component = props.component;
    this.order = props.order ?? 0;
    this.onOpen = props.onOpen;
    this.onClose = props.onClose;
  }

  open(props) {
    this.opened = true;
    this.props = props;
    this.onOpen?.();
  }

  close() {
    this.opened = false;
    this.onClose?.();
  }
}
