import React from 'react';
import { SvgIcon } from '../../atoms/svg-icon';
import { TextInput } from '../../atoms/text-input';
import { StyledButton } from './styles';

export const TextInputWithButton = ({ buttonDisabled, onButtonClick, buttonTitle, buttonIcon, ...rest }) => (
  <TextInput {...rest}>
    <StyledButton disabled={!!buttonDisabled} onClick={onButtonClick}>
      <span>{buttonTitle}</span>
      {buttonIcon && <SvgIcon icon={buttonIcon} />}
    </StyledButton>
  </TextInput>
);
