import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import log from 'loglevel';
import { ThemeProvider } from 'styled-components';
import { Reset } from 'styled-reset';
import { Normalize } from 'styled-normalize';
import {
  useEntryPoint,
  useFetchProjectInfo,
  useFetchProjectNavigation,
  useNavigation,
  useProject,
  useTheme,
} from './features/system/hooks/use-system-store';
import { GlobalStyle } from './ui/theme/global-styles';
import { Header } from './features/app/components/header';
import { Footer } from './features/app/components/footer';
import { ModalManagerProvider } from './features/modal/components/modal-manager-provider';
import { HomePage } from './pages/home';
import { TermsPage } from './pages/terms';
import { LiveStreamPage } from './pages/live-stream';
import { BroadcastArenaPage } from './pages/broadcast/arena';
import { RouteE } from './config/navigation.config';
import { WebviewPage } from './pages/webview';
import { CompetitionPage } from './pages/competition';
import { EventManagerContainer } from './features/3dvista/containers/event-manager-container';
import { NotificationsContainer } from './features/intro-tour/containers/notifications';
import { useDispatch3dvistaNavigationEvent } from './hooks/use-dispatch-3dvista-navigation-event';
import { JitsuContainer } from './features/app/containers/jitsu-container';
import { JitsuPageView } from './features/app/components/jitsu-page-view';
import { NavPage } from './pages/nav';

function App() {
  log.setLevel(process.env.REACT_APP_LOG_LEVEL || 'debug');
  log.debug('App component initialization started');

  const theme = useTheme();
  const fetchProjectInfo = useFetchProjectInfo();
  const fetchProjectNavigation = useFetchProjectNavigation();
  const dispatch3dvistaNavigationEvent = useDispatch3dvistaNavigationEvent();
  const project = useProject();
  const navigation = useNavigation();
  const entryPoint = useEntryPoint();

  useEffect(() => {
    fetchProjectInfo();
  }, [fetchProjectInfo]);

  useEffect(() => {
    // Expose the values to the window object
    window.cookiebotDomainEnabled = project.configuration?.cookiebotDomainEnabled;
    window.cookiebotDomainGroupId = project.configuration?.cookiebotDomainGroupId;
  }, [project]);

  useEffect(() => {
    if (project.configuration?.navigationId) {
      fetchProjectNavigation(project.configuration?.navigationId);
    }
  }, [fetchProjectNavigation, project.configuration?.navigationId]);

  useEffect(() => {
    const fireGAEvents = () => {
      if (project.configuration?.gaMeasurementId) {
        const gaMeasurementId = project.configuration?.gaMeasurementId;
        log.debug(`[ReactGA] Initializing Google Analytics with Measurement ID: ${gaMeasurementId}`);
        ReactGA.initialize(gaMeasurementId);
        const hitType = 'pageview';
        const page = '/';
        const { title } = document;
        ReactGA.send({
          hitType,
          page,
          title,
        });
        log.debug(`[ReactGA] Send ${hitType} event completed successfully for "${title}" (Path: ${page})`);
      }
    };

    const handleAccept = () => {
      if (window.Cookiebot.consent.statistics) {
        fireGAEvents();
      }
    };

    if (project.configuration?.cookiebotDomainEnabled) {
      window.addEventListener('CookiebotOnAccept', handleAccept);
      return () => {
        window.removeEventListener('CookiebotOnAccept', handleAccept);
      };
    }
    fireGAEvents();
  }, [project.configuration?.gaMeasurementId, project.configuration?.cookiebotDomainEnabled]);

  useEffect(() => {
    if (Object.keys(navigation).length && project.configuration?.entryNavEventId && entryPoint === 'root') {
      const timeoutId = setTimeout(() => {
        dispatch3dvistaNavigationEvent(project.configuration?.entryNavEventId);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    // Add a return statement to satisfy the ESLint rule
    return undefined;
  }, [dispatch3dvistaNavigationEvent, navigation, project.configuration?.entryNavEventId, entryPoint]);

  return (
    <Suspense fallback="Loading...">
      <JitsuContainer configuration={project?.configuration}>
        {({ jitsuClient }) => (
          <BrowserRouter>
            {!!jitsuClient && !!project.configuration?.jaTrackPageviews && <JitsuPageView />}
            <Reset />
            <Normalize />
            <React.Suspense fallback="loading...">
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <ModalManagerProvider />
                <EventManagerContainer />
                <div>
                  <Header showLogo={!!project.configuration?.displayHeaderLogo} />
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path={RouteE.liveStream} element={<LiveStreamPage />} />
                    <Route path={RouteE.broadcastArena} element={<BroadcastArenaPage />} />
                    <Route path={RouteE.webview} element={<WebviewPage />} />
                    <Route path={RouteE.competition} element={<CompetitionPage />} />
                    <Route path={RouteE.nav} element={<NavPage />} />
                    <Route path={RouteE.terms} element={<TermsPage />} />
                  </Routes>
                  <NotificationsContainer />
                  <Footer />
                </div>
              </ThemeProvider>
            </React.Suspense>
          </BrowserRouter>
        )}
      </JitsuContainer>
    </Suspense>
  );
}

export default App;
