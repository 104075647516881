import React from 'react';
import PropTypes from 'prop-types';
import { FixedRightSpace, StyledInput, StyledWrapper } from './styles';

export const TransparentTextInput = ({ children, ...rest }) => (
  <StyledWrapper>
    <StyledInput {...rest} />
    {children && <FixedRightSpace>{children}</FixedRightSpace>}
  </StyledWrapper>
);

TransparentTextInput.propTypes = {
  stretch: PropTypes.bool,
};
