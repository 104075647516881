import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
};

export const introTourSlice = createSlice({
  name: 'introTour',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload || [];
    },
  },
});

export const introTourActions = introTourSlice.actions;

export default introTourSlice.reducer;
