import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { FullscreenModalLayout } from '../../../../ui/layouts/fullscreen-modal-layout';
import { MenuFooter } from '../../components/footer';
import { StyledContainer } from './styled';
import { MenuItems } from '../../components/items';
import { useNavigation } from '../../../system/hooks/use-system-store';

const MenuModalComponent = ({ closeModal }) => {
  const { i18n } = useTranslation();
  const navigation = useNavigation();

  const navItems = navigation[i18n?.language]?.menuItems ?? [];

  return (
    <FullscreenModalLayout onClose={closeModal}>
      <StyledContainer>
        <MenuItems list={navItems} onClose={closeModal} />
        <MenuFooter onClose={closeModal} />
      </StyledContainer>
    </FullscreenModalLayout>
  );
};

export const MenuModal = modalRegistry.register({
  id: 'Menu',
  component: MenuModalComponent,
});
