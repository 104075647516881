import { apiClient } from '../api-client';
import { PROJECT_ID } from '../../config/common.config';

export class LocationAPI {
  /**
   *
   * @param id {string}
   * @returns {*}
   */
  static fetchById(id) {
    return apiClient.get(`/xps/${PROJECT_ID}/locations/${id}`);
  }
}
