import React from 'react';
import { StyledWrapper } from './styles';
import { ButtonClose } from '../../atoms/button-close';

export const FullscreenModalLayout = ({ children, className, onClose }) => (
  <StyledWrapper className={className}>
    {onClose && (
      <ButtonClose languageMod onClick={onClose}>
        <img src="/icons/close_round_icon.svg" alt="close" />
      </ButtonClose>
    )}
    {children}
  </StyledWrapper>
);
