import styled from 'styled-components';

export const StyledButton = styled.button`
  background: #1d4ed8;
  color: ${({ theme }) => theme.colors.$white};
  cursor: pointer;
  border: 3px solid #3b82f6;
  border-radius: 10px;
  outline: none;
  user-select: none;
  display: flex;
  align-items: center;
  margin-right: 5px;
  height: 40px;
  padding: 0 24px;
  transition:
    background-color ${({ theme }) => theme.animations.content} ease-in-out,
    border-color ${({ theme }) => theme.animations.content} ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.$primary700};
    border-color: ${({ theme }) => theme.colors.$primary200};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.$primary900};
    opacity: 0.5;
    border: 3px solid ${({ theme }) => theme.colors.$primary200};
    border-radius: 10px;
  }

  & span {
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.$white};
    text-shadow: 0 2px 4px ${({ theme }) => theme.colors.$primary800};
    margin-right: 10px;
  }
`;
