import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper } from './styles';

export const LocationPin = ({ onSelect, active, location }) => {
  const handleClick = useCallback(() => {
    onSelect(location);
  }, [location, onSelect]);

  if (typeof location.x === 'undefined' || typeof location.y === 'undefined') {
    return null;
  }

  return (
    <StyledWrapper active={active} top={location.y} left={location.x} onClick={handleClick}>
      <img id={`${location.x}_${location.y}`} src="/icons/eye.svg" alt="eye" />
    </StyledWrapper>
  );
};

LocationPin.propTypes = {
  active: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  location: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }).isRequired,
};
