import React, { useCallback, useRef } from 'react';
import MuxPlayerComponent from '@mux/mux-player-react';

export const MuxPlayer = ({ config = {}, muted, videoEmbedId, onPlay, onPlayError, play, onEnd }) => {
  const playerRef = useRef(null);

  const onDataLoaded = useCallback(
    (event) => {
      playerRef.current = event?.target;

      if (!play) {
        return;
      }

      event?.target
        .play()
        .then(() => {
          onPlay(true);
          onPlayError(false);
        })
        .catch(() => {
          onPlay(false);
          onPlayError(true);
        });
    },
    [play, onPlay, onPlayError],
  );

  return (
    <MuxPlayerComponent
      ref={playerRef}
      playsInline
      paused={!play}
      muted={muted}
      onEnded={onEnd}
      onPlay={() => onPlay(true)}
      onPause={() => onPlay(false)}
      onLoadedMetadata={onDataLoaded}
      streamType={config?.['stream-type']}
      playbackId={videoEmbedId}
      metadataVideoTitle="Placeholder (optional)"
      metadataViewerUserId="Placeholder (optional)"
      primaryColor="#FFFFFF"
      secondaryColor="#000000"
      metadata={{
        muted,
      }}
    />
  );
};
