import styled, { css } from 'styled-components';
import { media } from '../../../../ui/mixins/media';
import { FlexBox } from '../../../../ui/atoms/flex-box';

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.md(css`
    align-items: center;
    flex-direction: row;
    padding-top: 120px;
  `)}
`;

export const StyledStreaming = styled.div`
  width: 100%;

  ${media.md`
    width:50%;
    height: 100%;
  `}
`;

export const StyledTitle = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.$white};
  display: none !important;
  margin-bottom: 16px;

  & > img {
    flex: 0 0 30px;
  }

  & > span {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-left: 10px;
  }

  ${media.md(css`
    display: flex !important;
  `)}

  ${media.lg(css`
    & > span {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    }
  `)}
`;

export const LeftSide = styled.div`
  & > div:last-child {
    background: ${({ theme }) => theme.colors.$dark900};

    ${media.sm(css`
      margin-bottom: 26px;
      border: 3px solid ${({ theme }) => theme.colors.$dark700};
      box-shadow:
        0 20px 15px rgba(23, 24, 29, 0.3),
        0 10px 0 ${({ theme }) => theme.colors.$dark800};
      border-radius: 20px;
      height: fit-content;
    `)}
  }

  ${media.sm(css`
    height: fit-content;
    margin-bottom: 20px;
  `)}
`;
