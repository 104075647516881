import styled from 'styled-components';

export const StyledLogo = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ topLayer, theme }) => (topLayer ? theme.zIndex.topLayer : theme.zIndex.header)};
  padding: 16px 8px;
  max-width: 150px;

  & img {
    max-height: 70px;
    max-width: 100%;
  }
`;
