import styled from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledMenuWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  max-width: 100vw;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.$dark900};
  display: flex;
  justify-content: space-evenly;
  z-index: 3;
  transform: translate(-50%, 0);
  border: 2px solid ${({ theme }) => theme.colors.$dark700};
  border-radius: 16px 16px 0 0;

  ${media.xs`
width: 400px;
`}
`;
export const StyledIcon = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  width: 80px;
  justify-content: center;
  position: relative;
  & a {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    text-decoration: none;
  }
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 1px;
    height: 18px;
    background-color: ${({ theme }) => theme.colors.$dark800};
  }
  &:last-child::before {
    content: '';
    width: 0;
    height: 0;
  }
  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 9px;
    right: 22px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: ${({ theme }) => theme.colors.$danger};
    color: ${({ theme }) => theme.colors.$white};
    padding: 4px;
    font-size: 8px;
  }
`;
