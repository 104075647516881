import styled from 'styled-components';
import { FlexBox } from '../../../../ui/atoms/flex-box';
import { media } from '../../../../ui/mixins/media';
import { StyledTitle } from '../live-stream-container/styles';

export const StyledFrame = styled.div`
  height: auto;
  width: 100%;

  ${media.md`
    border-left: 1px solid ${({ theme }) => theme.colors.$dark700};
    width:50%;
    height: 100%;
    margin-left: 20px;
  `}
`;

export const StyledTitleHead = styled(StyledTitle)`
  margin-bottom: 8px;

  & button {
    margin-right: 10px;
  }

  ${media.md`
  border-bottom: 1px solid ${({ theme }) => theme.colors.$dark700};
  padding: 0 0 16px 20px;

  & > span {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    }
  `}
`;

export const StyledHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  & button {
    margin-right: 10px;
  }

  & img {
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }
  ${media.md`
    border-bottom: 1px solid ${({ theme }) => theme.colors.$dark700};
    padding: 0 0 16px 20px;

    & > span {
    font-weight: 700;
    font-size: 16px;
    margin-left:10px;
    line-height: 36px;
    }
  `}
`;

export const StyledFeedList = styled.div`
  overflow-y: scroll;
  height: auto;
  max-height: 30vh;
  -ms-overflow-style: none;
  margin: 0 16px;

  ${media.md`
  margin-left: 0;
  height: calc(100% - 267px);
  max-height: 100%;
  margin: 0 0;
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    ${media.md`
      padding: 20px 0 20px 20px;
    `}
  }
`;

export const StyledLoading = styled(FlexBox)`
  margin-left: auto;
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;

  ${media.md`
  font-size: 14px;
  font-weight: 500;
  `}

  & img {
    margin-left: 10px;
  }
`;
