import { apiClient } from '../api-client';
import { PROJECT_ID } from '../../config/common.config';

export class EventAPI {
  /**
   *
   * @param type {string}
   * @param eventId {number}
   * @returns {*}
   */
  static getEventDetails({ type, eventId }) {
    return apiClient.get(`/elements/${PROJECT_ID}/${type}/${eventId}`);
  }
}
