import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WebViewModal } from '../../features/webview/modals/webview-modal';

export const WebviewComponent = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    const src = searchParams.get('src');
    if (src) {
      WebViewModal.open({ id, src });
    }
  });

  return null;
};

export const WebviewPage = WebviewComponent;
