import styled, { css } from 'styled-components';

export const StylesSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  cursor: inherit;
  outline: none;
  color: ${({ theme }) => theme.colors.$white};
  font-weight: 500;
  font-size: 14px;
  ${({ welcomeMode }) =>
    welcomeMode &&
    css`
      border: 1px solid #fff;
      border-radius: 10px;
      padding-left: 10px;
      font-size: 14px;
    `}

  & ::-ms-expand {
    display: none;
  }

  & option {
    color: black;
  }
`;
