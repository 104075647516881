import styled from 'styled-components';

export const FlexBox = styled.div((props) => ({
  display: props.inline ? 'inline-flex' : 'flex',
  justifyContent: props.justifyContent || 'flex-start',
  flexDirection: props.direction || 'row',
  flexGrow: props.flexGrow || 0,
  flexBasis: props.flexBasis || 'auto',
  flexShrink: props.flexShrink || 1,
  flexWrap: props.flexWrap || 'nowrap',
  flex: props.flex || '0 1 auto',
  alignItems: props.alignItems || 'stretch',
}));
