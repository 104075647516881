import React from 'react';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { LiveStreamTwitterContainer } from '../../containers/live-stream-container';
import { StyledLayout } from './styles';

const LiveStreamTwitterComponent = ({ closeModal, id }) => (
  <StyledLayout onClose={closeModal}>
    <LiveStreamTwitterContainer id={id} />
  </StyledLayout>
);

export const LiveStreamTwitterModal = modalRegistry.register({
  id: 'Live Stream Twitter',
  component: LiveStreamTwitterComponent,
});
