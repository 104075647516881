import { apiClient } from '../api-client';
import { PROJECT_ID } from '../../config/common.config';

export class NewsletterAPI {
  /**
   * @param id
   * @param body
   * @param body.emailAddress {string}
   * @param body.content {boolean}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static subscribe({ id, body }) {
    const data = new FormData();
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in body) {
      data.append(key, body[key]);
    }
    return apiClient.post(`/xps/${PROJECT_ID}/newsletters/${id}/subscribe`, data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }
}
