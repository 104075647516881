import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectEventsQueue } from '../store/system.selectors';
import { usePerformEvent } from '../../app/hooks/use-perform-event';
import { systemSliceActions } from '../store/system.slice';

export const useCheckEventsQueue = () => {
  const dispatch = useDispatch();
  const eventsQueue = useSelector(selectEventsQueue);
  const performEvent = usePerformEvent();

  return useCallback(() => {
    if (eventsQueue.length) {
      dispatch(systemSliceActions.shiftEventFromQueue());
      performEvent(eventsQueue[0]);
    }
  }, [dispatch, eventsQueue, performEvent]);
};
