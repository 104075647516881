import React from 'react';
import { modalRegistry } from '../../../modal/providers/modal.registry';

const AccessCodeModalComponent = React.lazy(() => import('./access-code-modal'));

export const AccessCodeModal = modalRegistry.register({
  id: 'AccessCode',
  preventEasyClose: true,
  component: AccessCodeModalComponent,
});
