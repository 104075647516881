import styled from 'styled-components';

export const LockOverlay = styled.div`
  opacity: 0.96;
  background: rgba(39, 41, 51, 0.7);
  backdrop-filter: blur(2px);

  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.lockOverlay};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
