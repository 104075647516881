import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { modalRegistry } from '../../providers/modal.registry';
import { ModalContext } from '../../providers/modal.context';
import ModalComponent from '../modal-component';
import { StyledWrapper } from './styles';
import { LockOverlay } from '../../../../ui/atoms/lock-overlay';

export const ModalManagerProvider = ({ children }) => {
  const [modals, setModals] = useState([...modalRegistry.modals.values()]);

  const isActive = useMemo(() => modals.find((modal) => modal.opened), [modals]);

  const contextValue = useMemo(
    () => ({
      openedModals: modals,
    }),
    [modals],
  );

  useEffect(() => {
    modalRegistry.subscribe(setModals);

    return () => {
      modalRegistry.unsubscribe(setModals);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isActive]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {!!modals.length &&
        ReactDOM.createPortal(
          <StyledWrapper active={!!isActive}>
            {[...modals].reverse().map((modal, index) => (
              <ModalComponent modal={modal} isLast={index === modals.length} key={modal.id} />
            ))}
            <LockOverlay />
          </StyledWrapper>,
          document.body,
        )}
      {isActive && <LockOverlay />}
    </ModalContext.Provider>
  );
};
