import React from 'react';
import { LogoHeader } from './styles';
import { Logo } from '../logo';

export const Header = ({ showLogo }) =>
  showLogo ? (
    <LogoHeader>
      <Logo />
    </LogoHeader>
  ) : null;
