import styled from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledContainer = styled.div`
  padding: 75px 0 230px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 100%;
  position: relative;

  ${media.md`
  padding: 60px 0 240px 0;
  `}
`;
