import { useEffect } from 'react';

export const useWindowListener = (event, cb, disabled = false) => {
  useEffect(() => {
    if (!disabled) {
      window.addEventListener(event, cb);
    }

    return () => {
      window.removeEventListener(event, cb);
    };
  }, [disabled, cb, event]);
};
