import styled from 'styled-components';
import { media } from '../../mixins/media';

export const StyledSpacing = styled.div`
  margin: ${({ margin }) => margin};
  margin-left: ${({ ml }) => ml};
  margin-right: ${({ mr }) => mr};
  margin-top: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};

  padding: ${({ padding }) => padding};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};
  padding-top: ${({ pt }) => pt};
  padding-bottom: ${({ pb }) => pb};

  ${({ marginSm, paddingSm, mlSm, mrSm, mtSm, mbSm, ptSm, prSm, pbSm, plSm }) => media.sm`
      margin: ${marginSm};
      margin-left: ${mlSm};
      margin-right: ${mrSm};
      margin-top: ${mtSm};
      margin-bottom: ${mbSm};
      padding: ${paddingSm};
      padding-left: ${plSm};
      padding-right: ${prSm};
      padding-top: ${ptSm};
      padding-bottom: ${pbSm};
    `};

  ${({ marginMd, paddingMd, mtMd, mrMd, mbMd, mlMd, ptMd, prMd, pbMd, plMd }) => media.md`
      margin: ${marginMd};
      margin-left: ${mlMd};
      margin-right: ${mrMd};
      margin-top: ${mtMd};
      margin-bottom: ${mbMd};
      padding: ${paddingMd};
      padding-left: ${plMd};
      padding-right: ${prMd};
      padding-top: ${ptMd};
      padding-bottom: ${pbMd};
    `};
`;
