import { ModalEntity } from './modal.entity';

class ModalRegistry {
  modals = new Map();

  opened = new Map();

  listeners = [];

  onModalStateChanged(id, state = false) {
    const modal = this.get(id);
    if (modal) {
      this.opened.set(id, state);
      this.emit();
    }
  }

  /**
   * @param modalArgs.id {string}
   * @param modalArgs.component {function}
   * @returns {ModalEntity}
   */
  register(modalArgs) {
    const modal = new ModalEntity({
      ...modalArgs,
      onOpen: () => this.onModalStateChanged(modalArgs.id, true),
      onClose: () => this.onModalStateChanged(modalArgs.id, false),
    });
    this.modals.set(modal.id, modal);
    this.emit();
    return modal;
  }

  emit() {
    this.listeners.forEach((cb) =>
      cb(
        [...this.modals.values()].map((modal) => ({
          ...modal,
          opened: !!this.opened.get(modal.id),
        })),
      ),
    );
  }

  subscribe(cb) {
    this.listeners.push(cb);
  }

  unsubscribe(cb) {
    this.listeners.filter((l) => l !== cb);
  }

  get(id) {
    return this.modals.get(id);
  }
}

export const modalRegistry = new ModalRegistry();
