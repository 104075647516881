import styled, { css } from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 100%;
  min-height: 100%;
  padding: 24px 0;

  ${media.md(css`
    flex-direction: row;
    padding: 60px 0;
  `)}
`;

export const StyledMapImage = styled.img`
  width: 100%;
`;

export const FloorMap = styled.div`
  flex: 1;
  max-width: 780px;
  position: relative;
  text-align: center;
  height: fit-content;
`;
