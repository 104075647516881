import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const FixedRightSpace = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 2px solid #334155;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.$white};
  font-weight: 500;
  font-size: 14px;
  transition: border-color ${({ theme }) => theme.animations.input} ease-in-out};
  
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.colors.$primary200};
  }

  ${({ stretch }) => stretch && 'width: 100%;'};
`;
