export const FONT_SIZE = {
  h1: '52px',
  h2: '40px',
  h3: '32px',
  h4: '24px',
  h5: '18px',
  h6: '16x',
  bodyL: '14px',
  bodyM: '12px',
  bodyS: '10px',
};
