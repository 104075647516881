import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  games: {},
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    completeGame: (state, action) => {
      const { id, name } = action.payload;
      if (state.games[id]) {
        state.games[id].status = 'completed';
      } else {
        state.games[id] = {
          linkedXpId: id,
          linkedXp: name,
          status: 'completed',
          value: 0,
        };
      }
    },
    setGames: (state, action) => {
      state.games = Object.values(action.payload ?? {}).reduce((acc, game) => {
        const { linkedXp, linkedXpId, value } = game;
        acc[linkedXpId] = {
          linkedXpId,
          linkedXp,
          status: state.games[linkedXpId]?.status ?? 'incomplete',
          value: parseInt(value ?? 0, 10),
        };
        return acc;
      }, {});
    },
  },
});

export const gameSliceActions = gameSlice.actions;

export default gameSlice.reducer;
