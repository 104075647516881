import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { selectGames } from '../store/game.selectors';
import { gameSliceActions } from '../store/game.slice';

export const useCompleteGame = () => {
  const dispatch = useDispatch();

  return useCallback(
    (payload) => {
      dispatch(gameSliceActions.completeGame(payload));
    },
    [dispatch],
  );
};

export const useEarnedPoints = () => {
  const games = useSelector(selectGames);

  return useMemo(
    () =>
      Object.values(games).reduce((acc, game) => {
        if (game.status === 'completed') {
          acc += game.value;
        }
        return acc;
      }, 0),
    [games],
  );
};
