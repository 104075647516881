import React, { forwardRef } from 'react';
import { StyledSpacing } from './styles';

export const Spacing = forwardRef((props, ref) => (
  <StyledSpacing ref={ref} {...props}>
    {props.children}
  </StyledSpacing>
));

Spacing.displayName = 'Spacing';
