import React from 'react';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { ModalLayout } from '../../../../ui/layouts/modal-layout';
import { NewsletterForm } from '../../components/newsletter-form';

const NewsletterModalComponent = ({ closeModal, id }) => (
  <ModalLayout onClose={closeModal} icon="/icons/letter.svg">
    <NewsletterForm id={id} />
  </ModalLayout>
);

export const NewsletterModal = modalRegistry.register({
  id: 'Newsletter',
  component: NewsletterModalComponent,
});
