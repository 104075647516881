import styled from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledFooter = styled.div`
  position: absolute;
  right: -20px;
  left: -20px;
  bottom: 0;
  height: 180px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(58, 61, 78, 0.8);

  ${media.md`
    right: -40px;
    left: -40px;
    height: 200px;
  `}
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${media.md`
    max-width: 1124px;
  `}
`;

export const FooterNav = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  justify-content: space-around;

  & div {
    cursor: pointer;

    &:first-child {
      align-items: center;
    }
  }
`;

export const LangSwitcherWrapper = styled.div`
  display: flex;

  & div {
    cursor: default;
    margin-right: 3px;
    white-space: nowrap;
  }

  & select {
    cursor: pointer;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  height: 100%;
`;
