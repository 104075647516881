import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledStreaming, StyledTitle, StyledWrapper } from './styles';
import { LiveFeedContainer } from '../live-feed-container';
import { Typography } from '../../../../ui/atoms/typography';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';
import { MediaUnit } from '../../../../ui/organisms/media-unit';

export const LiveStreamTwitterContainer = ({ id }) => {
  const { t } = useTranslation(`livestream-twitter/${id}`);
  const mediaItem = t('content.mediaItem', { returnObjects: true })?.[0] || {};
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  useEffect(() => {
    dispatchCompleteEvent({
      id,
      name: FEATURE_NAMES.liveStreamTwitter,
    });
  }, [dispatchCompleteEvent, id]);

  return (
    <StyledWrapper>
      <StyledStreaming>
        <StyledTitle alignItems="center">
          <img src={t('content.mediaSectionIcon.filename')} width={30} />
          <Typography bold size="heading2">
            {t('content.mediaSectionTitle')}
          </Typography>
        </StyledTitle>
        <div>
          <MediaUnit {...mediaItem} />
        </div>
      </StyledStreaming>
      <LiveFeedContainer id={id} />
    </StyledWrapper>
  );
};
