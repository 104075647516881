import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getMediaConfig } from './lib';
import { Controls } from './controls';
import { StyledImage, StyledMediaWrapper, StyledVideoWrapper } from './styles';
import { VimeoPlayer } from './vimeo-player';
import { MuxPlayer } from './mux-player';
import { YoutubePlayer } from './youtube-player';
import { useGetWindowSize } from '../../../hooks/use-get-window-size';
import { VideoPlayError } from './video-play-error';

export const MediaUnit = ({
  mediaEmbedConfigProfile,
  commonConfigProfile = 'genericXpDefault',
  videoEmbedId,
  videoPlatform,
  videoHeight,
  videoWidth,
  image,
  forcePlay,
  onEnded,
  mediaClassName,
}) => {
  const windowSize = useGetWindowSize();
  const [videoFramePadding, setVideoFramePadding] = useState(20);
  const videoWrapperRef = useRef();
  const imageUrl = image?.filename;
  const isImage = !videoPlatform && imageUrl;
  const { global = {}, config = {} } =
    getMediaConfig(commonConfigProfile, {
      mediaEmbedConfigProfile,
      videoPlatform,
    }) || {};

  const initialPlay = typeof config.autoplay === 'undefined' ? true : !!config.autoplay;

  const [play, setPlay] = useState(typeof forcePlay !== 'undefined' ? forcePlay : initialPlay);
  const [muted, setMuted] = useState(typeof config.muted === 'undefined' ? true : config.muted);
  const [autoPlayError, setAutoPlayError] = useState(false);

  let initialCCState = false;

  if (config.showCaptions !== undefined) {
    initialCCState = config.showCaptions;
  } else if (config.textTrack !== undefined) {
    initialCCState = config.textTrack;
  }

  const [ccEnabled, setCcEnabled] = useState(initialCCState);

  const width = parseInt(videoWidth, 10) || 0;
  const height = parseInt(videoHeight, 10) || 0;

  const isPortrait = false; // videoHeight > videoWidth;

  const videoOffset = useMemo(() => {
    if (!width || !height) {
      return '56.25%';
    }
    if (height > width) {
      return `${(width / height) * 100}%`;
    }
    return `${(height / width) * 100}%`;
  }, [height, width]);

  useEffect(() => {
    if (!videoWrapperRef.current) {
      return;
    }

    if (!width || !height) {
      return;
    }

    if (width < height) {
      setVideoFramePadding(20);
    }

    const offset = width / height;

    const { height: wrapperHeight, width: wrapperWidth } = videoWrapperRef.current.getBoundingClientRect();
    const w = wrapperHeight * offset;
    const padding = (wrapperWidth - w) / 2 + 20;
    setVideoFramePadding(padding);
  }, [height, videoOffset, width, windowSize, videoEmbedId]);

  const triggerPlay = useCallback(() => {
    setMuted(false);
    setAutoPlayError(false);
    setPlay(true);
  }, []);

  const handleEnded = useCallback(() => {
    setPlay(false);
    onEnded?.();
  }, [onEnded]);

  let content = null;

  if (isImage) {
    content = <StyledImage src={imageUrl} />;
  }

  useEffect(() => {
    if (typeof forcePlay !== 'undefined') {
      setPlay(forcePlay);
    }
  }, [forcePlay]);

  if (videoPlatform === 'vimeo') {
    content = (
      <StyledVideoWrapper
        data-video-id={videoEmbedId}
        className={mediaClassName}
        ref={videoWrapperRef}
        $isPortrait={isPortrait}
        $offset={videoOffset}
      >
        {autoPlayError && <VideoPlayError onPlay={triggerPlay} />}
        <VimeoPlayer
          config={config}
          videoEmbedId={videoEmbedId}
          muted={muted}
          play={play}
          onPlay={setPlay}
          onEnd={handleEnded}
          onPlayError={setAutoPlayError}
          cc={ccEnabled}
        />
      </StyledVideoWrapper>
    );
  }

  if (videoPlatform === 'youtube') {
    content = (
      <StyledVideoWrapper
        data-video-id={videoEmbedId}
        className={mediaClassName}
        ref={videoWrapperRef}
        $isPortrait={isPortrait}
        $offset={videoOffset}
      >
        <YoutubePlayer
          config={config}
          play={play}
          onPlay={setPlay}
          muted={muted}
          onEnd={handleEnded}
          videoEmbedId={videoEmbedId}
          onPlayError={setAutoPlayError}
        />
        {autoPlayError && <VideoPlayError onPlay={triggerPlay} />}
      </StyledVideoWrapper>
    );
  }

  if (videoPlatform === 'mux') {
    content = (
      <StyledVideoWrapper
        data-video-id={videoEmbedId}
        $controls={config?.controls}
        className={mediaClassName}
        ref={videoWrapperRef}
        $offset={videoOffset}
      >
        {autoPlayError && <VideoPlayError onPlay={triggerPlay} />}
        <MuxPlayer
          muted={muted}
          videoEmbedId={videoEmbedId}
          play={play}
          onPlay={setPlay}
          onPlayError={setAutoPlayError}
          onEnd={handleEnded}
        />
      </StyledVideoWrapper>
    );
  }

  if (!content) {
    return null;
  }

  return (
    <StyledMediaWrapper>
      {!isImage && (
        <Controls
          {...global}
          onMuteChange={setMuted}
          onPlayChange={setPlay}
          onCCChange={(value) => {
            console.log('CC Change:', value);
            setCcEnabled(value);
          }}
          play={play}
          muted={muted}
          cc={ccEnabled}
          leftStyle={{ left: `${videoFramePadding}px` }}
          rightStyle={{ right: `${videoFramePadding}px` }}
        />
      )}
      {content}
    </StyledMediaWrapper>
  );
};

MediaUnit.propTypes = {
  commonConfigProfile: PropTypes.string.isRequired,
  forcePlay: PropTypes.bool,
  onEnded: PropTypes.func,
  videoHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  videoWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaEmbedConfigProfile: PropTypes.string,
  mediaClassName: PropTypes.string,
  videoEmbedId: PropTypes.string,
  videoPlatform: PropTypes.oneOf(['youtube', 'vimeo', 'mux']),
  cc: PropTypes.bool,
  onCCChange: PropTypes.func,
  image: PropTypes.shape({
    filename: PropTypes.string.isRequired,
  }),
};
