import styled from 'styled-components';

export const StyledWrapper = styled.div`
  background: rgba(58, 61, 78, 0.3);
  color: ${({ theme }) => theme.colors.$white};
  padding: 16px;
  border-radius: 10px;
  text-align: center;

  & span {
    text-align: start;
  }
`;

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

export const StyledAdvList = styled.div`
  margin: 10px 0 18px;

  & > div + div {
    margin-top: 4px;
  }

  & img {
    flex: 0 0 12px;
    width: 12px;
    margin-right: 8px;
  }

  & span {
    font-size: 10px;
    line-height: 15px;
  }
`;
