import React, { useCallback, useEffect, useRef } from 'react';
import YouTube from '@u-wave/react-youtube';
import { useTranslation } from 'react-i18next';
import { playTestVideo } from './lib';

export const YoutubePlayer = ({ config = {}, cc, videoEmbedId, onPlay, onPlayError, play, onEnd }) => {
  const initRef = useRef(false);
  const playerRef = useRef(null);
  const { i18n } = useTranslation();

  const onReady = useCallback(
    (event) => {
      console.log('Event: onReady', event);
      playerRef.current = event.target;

      if (!play) {
        return;
      }

      playTestVideo().then((canPlay) => {
        if (canPlay) {
          playerRef.current.loadVideoById({
            videoId: videoEmbedId,
            playerVars: { cc_load_policy: cc || config.showCaptions ? 1 : 0, cc_lang_pref: i18n.language },
          });
          onPlayError(false);
          onPlay(true);
        } else {
          onPlayError(true);
          onPlay(false);
        }
      });
    },
    [cc, config.showCaptions, onPlay, onPlayError, play, videoEmbedId, i18n.language],
  );

  // Debug: Log each time the CC state is toggled on/off to debug the CC button
  useEffect(() => {
    console.log('CC:', cc);
    console.log('showCaptions:', config.showCaptions);
  }, [cc, config.showCaptions]);

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      return;
    }

    if (!playerRef.current) {
      return;
    }

    if (play) {
      playTestVideo().then((canPlay) => {
        if (canPlay) {
          playerRef.current.loadVideoById({
            videoId: videoEmbedId,
            playerVars: { cc_load_policy: cc ? 1 : 0, cc_lang_pref: i18n.language },
          });
          onPlayError(false);
          onPlay(true);
        } else {
          onPlayError(true);
          onPlay(false);
        }
      });
    } else {
      playerRef.current.pauseVideo();
    }
  }, [cc, play, videoEmbedId, i18n.language, onPlay, onPlayError]);

  return (
    <YouTube
      playsInline
      onPause={() => {
        console.log('Event: onPause', 'Current Time:', playerRef.current?.getCurrentTime());
        onPlay(false);
      }}
      onReady={onReady}
      onEnd={() => {
        console.log('Event: onEnd', 'Current Time:', playerRef.current?.getCurrentTime());
        onEnd();
      }}
      onError={(errorPayload) => {
        console.log('Event: onError', errorPayload, 'Current Time:', playerRef.current?.getCurrentTime());
        onPlayError(true);
        onPlay(false);
      }}
      onStateChange={(event) => {
        console.log('Event: onStateChange', event, 'Current Time:', playerRef.current?.getCurrentTime());
      }}
      onBuffering={() => {
        console.log('Event: onBuffering', 'Current Time:', playerRef.current?.getCurrentTime());
      }}
      onCued={() => {
        console.log('Event: onCued', 'Current Time:', playerRef.current?.getCurrentTime());
      }}
      onPlaying={() => {
        console.log('Event: onPlaying', 'Current Time:', playerRef.current?.getCurrentTime());
        onPlay(true);
      }}
      video={videoEmbedId}
      loop={config.loop}
      disableKeyboard={config.disableKeyboard}
      allowFullscreen={config.allowFullscreen}
      modestBranding={config.modestBranding}
      annotations={config.annotations}
      controls={!!config.controls}
      showRelatedVideos={config.showRelatedVideos}
      showCaptions={cc || config.showCaptions}
      playerVars={{
        cc_load_policy: cc && config.showCaptions ? 1 : 0,
        cc_lang_pref: i18n.language,
      }}
    />
  );
};
