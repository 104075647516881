import styled, { css } from 'styled-components';
import { media } from '../../mixins/media';

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  border-radius: 16px;
  margin: auto;
  display: block;
`;

export const StyledVideoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-height: 100%;
  user-select: none;

  ${({ $controls }) =>
    !$controls &&
    css`
      & mux-player {
        /* Hide all controls at once */
        --controls: none;
      }
    `}

  &::after {
    content: '';
    display: block;
    padding-top: ${({ $isPortrait, $offset }) => ($isPortrait ? '0' : $offset)};
    padding-bottom: ${({ $isPortrait }) => ($isPortrait ? '177.78%' : '0')};
  }

  & iframe,
  & video,
  & mux-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${media.sm`
    border-radius: 20px 20px 0 0;
  `}
`;

export const StyledMediaWrapper = styled.div`
  position: relative;
`;

export const StyledPlayError = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);

  & > div {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5rem;
    white-space: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & span {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.$white};
    margin-top: 10px;
  }

  ${media.md(css`
    & > div {
      white-space: nowrap;
      font-size: 2rem;
    }
  `)}
`;

// Move position properties to StyledButtonGroup
export const StyledButtonGroup = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  ${({ position }) => css`
    ${position === 'top' ? 'top: 20px;' : 'bottom: 20px;'}
  `}

  ${({ $left }) => css`
    ${$left ? 'left: 20px;' : 'right: 20px;'}
  `}
`;

export const StyledControl = styled.div`
  z-index: 2;
  cursor: pointer;

  & + & {
    margin-left: 8px;
  }

  & svg {
    filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4));
  }
`;
