import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.$dark900};
  }
  body,
  button {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.text};
  }
  
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  
  input {
    border: none;
  }
  
  button {
    outline: none;
    border: none;
  }
`;
