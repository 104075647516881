import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../ui/atoms/typography';
import { Logo } from '../../../app/components/logo';
import { StyledFooter, FooterContainer, FooterNav, FooterLogo, LangSwitcherWrapper } from './styled';
import { LanguageSwitcher } from '../../../system/components/language-switcher';
import { useNavigation } from '../../../system/hooks/use-system-store';
import { useHandleEvent } from '../../../app/hooks/use-handle-event';

export const MenuFooter = ({ onClose }) => {
  const { i18n } = useTranslation();
  const navigation = useNavigation();
  const handleEvent = useHandleEvent();

  const navItems = navigation[i18n?.language]?.menuFooterItems ?? [];

  const onItemClick = useCallback(
    (eventId) => {
      handleEvent(eventId);
      onClose();
    },
    [handleEvent, onClose],
  );

  return (
    <StyledFooter>
      <FooterContainer>
        <FooterNav>
          <LangSwitcherWrapper>
            <Typography tag="div" color="#94A3B8" size="bodyL" medium>
              Language:
            </Typography>
            <LanguageSwitcher />
          </LangSwitcherWrapper>
          {navItems?.map((item) => (
            <Typography key={item.name} onClick={() => onItemClick(item.eventId)} tag="div" size="h6" semiBold>
              {item.name}
            </Typography>
          ))}
        </FooterNav>
        <FooterLogo>
          <Logo />
        </FooterLogo>
      </FooterContainer>
    </StyledFooter>
  );
};
