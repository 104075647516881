import styled from 'styled-components';

export const StyledInnerContent = styled.div`
  & > * + * {
    margin-top: 20px;
  }

  & h1,
  & h2,
  & h3 {
    line-height: 1.25;
  }

  & p {
    margin-bottom: 10px;
  }
`;
