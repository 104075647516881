import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import log from 'loglevel';
import { useEventBindings } from './use-event-bindings';
import { useSendJitsuEvent } from './use-send-jitsu-event';
import { useProject } from '../../system/hooks/use-system-store';

export const usePerformEvent = () => {
  const eventBindings = useEventBindings();
  const sendJitsuEvent = useSendJitsuEvent();
  const project = useProject();
  const { configuration } = project || {};
  const { cookiebotDomainEnabled } = configuration || {};

  return useCallback(
    (event) => {
      try {
        log.debug('[Analytics] usePerformEvent: Start processing event', event);
        const { detail } = event;
        if (!detail) {
          log.warn('[Analytics] usePerformEvent: Event detail is not provided');
          return;
        }
        const { type, name, ...rest } = detail;
        log.debug(`[Analytics] usePerformEvent: Event type - ${type}, name - ${name}, rest - ${JSON.stringify(rest)}`);
        if (eventBindings[name]) {
          eventBindings[name](rest);
          if (!cookiebotDomainEnabled || window.Cookiebot.consent.statistics) {
            ReactGA.send({
              hitType: 'pageview',
              page: `/xps/${name}/${rest.id}`,
              title: `XPs > ${name}`,
            });
            log.debug(
              `[ReactGA] Send pageview event completed successfully for`,
              `"XPs > ${name}" (Path: /xps/${name}/${rest.id})`,
            );
            sendJitsuEvent('Navigation > Open XP', { xpId: rest.id, xpType: name });
            log.debug('[Jitsu] Send event completed for "Navigation > Open XP"');
          }
        } else {
          log.error(`[Analytics] usePerformEvent: Event ${name} doesn't exist`);
        }
      } catch (err) {
        log.error('[Analytics] usePerformEvent: An error occurred', err);
      }
    },
    [eventBindings, sendJitsuEvent, cookiebotDomainEnabled],
  );
};
