/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { defaultTheme } from '../../../ui/config/default-theme';
import { fetchProjectInfoThunk, fetchProjectNavigationThunk } from './system.thunk';
import { ProjectModel } from '../../../api/project/project.model';
import { ThemeModel } from '../../../api/project/theme.model';

/**
 * @type {{project: ProjectModel, theme: {fonts: {text: string, title: string}, animations: {input: string, content: string}, colors: {secondary: string, error: string, primary: string}, zIndex: {lockOverlay: number, footer: number, backButton: number, header: number, page: number, topLayer: number, modal: number, videoContent: number}}}}
 */
const initialState = {
  theme: defaultTheme,
  project: { ...new ProjectModel({}) },
  navigation: {},
  eventsQueue: [],
  entryPoint: 'root', // root, nav
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    addEventsToQueue: (state, { payload }) => {
      state.eventsQueue = [...new Set([...state.eventsQueue, ...payload])];
    },
    setEntryPoint: (state, { payload }) => {
      state.entryPoint = payload;
    },
    shiftEventFromQueue: (state) => {
      if (state.eventsQueue.length) {
        state.eventsQueue.shift();
      }
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(fetchProjectInfoThunk.fulfilled, (state, action) => {
        state.project = { ...new ProjectModel(action.payload?.data) };
        state.theme = { ...new ThemeModel(action.payload?.data.styles) };
      })
      .addCase(fetchProjectNavigationThunk.fulfilled, (state, action) => {
        state.navigation = action.payload?.data?.content || {};
      });
  },
});

export const systemSliceActions = systemSlice.actions;

export default systemSlice.reducer;
