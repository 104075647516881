import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const FixedRightSpace = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  outline: none;
  background: #334155;
  border-radius: 10px;
  border: 1px solid transparent;
  height: 50px;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors.$white};
  font-weight: 600;
  font-size: 14px;
  transition: border-color ${({ theme }) => theme.animations.input} ease-in-out};
  
  &:active,
  &:focus {
    border-color: #60A5FA;
  }

  ${({ stretch }) => stretch && 'width: 100%;'};
`;
