import axios from 'axios';
import { PROJECT_API_KEY, CONTENT_API_URL } from '../config/common.config';

export const apiClient = axios.create({
  baseURL: CONTENT_API_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    config.headers['xspace-api-key'] = PROJECT_API_KEY;
    return config;
  },
  (error) => Promise.reject(error),
);
