export const LANGUAGES = ['en', 'fr', 'de'];

export const STORAGE_KEYS = {
  lang: 'lang',
  loggedIn: 'logged_in',
  welcomeVisited: 'welcome_visited',
  storePersistor: 'persist:root',
};

export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || '';
export const PROJECT_API_KEY = process.env.REACT_APP_PROJECT_API_KEY || '';
export const CONTENT_API_URL = process.env.REACT_APP_CONTENT_API_URL || '';
