import React from 'react';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { FullscreenModalLayout } from '../../../../ui/layouts/fullscreen-modal-layout';
import { HotContainer } from '../../containers';

const HotModalComponent = ({ closeModal }) => (
  <FullscreenModalLayout onClose={closeModal}>
    <HotContainer onClose={closeModal} />
  </FullscreenModalLayout>
);

export const HotModal = modalRegistry.register({
  id: 'Hot',
  component: HotModalComponent,
});
