import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDispatch3dvistaNavigationEvent } from '../../hooks/use-dispatch-3dvista-navigation-event';
import { systemSliceActions } from '../../features/system/store/system.slice';

export const NavPage = () => {
  const navigate = useNavigate();
  const { event } = useParams();
  const dispatch = useDispatch();
  const dispatch3dvistaNavigationEvent = useDispatch3dvistaNavigationEvent();

  useEffect(() => {
    navigate(`/${window.location.search}${window.location.hash}`);
    if (event) {
      dispatch3dvistaNavigationEvent(event);
      dispatch(systemSliceActions.setEntryPoint('nav'));
    }
  }, [dispatch, dispatch3dvistaNavigationEvent, event, navigate]);

  return null;
};
