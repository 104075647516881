export const defaultTheme = {
  fonts: {
    title: 'Poppins',
    text: 'Poppins',
  },
  colors: {
    $info: '#17A2B8',
    $success: '#28A745',
    $danger: '#DC3545',
    $warning: '#FFC107',
    $white: '#DC3545',
    $light: '#FFFFFF',
    $light200: '#E2E8F0',
    $light300: '#94A3B8',
    $dark900: '#272933',
    $dark800: '#3A3D4E',
    $dark700: '#505363',
    $primary200: '#3986D0',
    $primary700: '#004990',
    $primary800: '#1E3A8A',
    $primary900: '#00366B',
    $secondary200: '#FFE1A7',
    $secondary500: '#ED8000',
    $secondary600: '#F68603',
    $secondary800: '#AF6509',
  },
  zIndex: {
    lockOverlay: 99,
    header: 999,
    footer: 8,
    modal: 999,
    page: 999,
    videoContent: 9999,
    backButton: 99999,
    topLayer: 99999999,
  },
  animations: {
    input: '150ms',
    content: '200ms',
  },
};
