import React from 'react';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { FullscreenModalLayout } from '../../../../ui/layouts/fullscreen-modal-layout';
import { FloorPlanContainer } from '../../containers/floor-plan-container';
import { StyledWrapper } from './styles';

const FloorPlanModalComponent = ({ closeModal, ...rest }) => (
  <FullscreenModalLayout onClose={closeModal}>
    <StyledWrapper>
      <FloorPlanContainer {...rest} />
    </StyledWrapper>
  </FullscreenModalLayout>
);

export const FloorPlanModal = modalRegistry.register({
  id: 'FloorPlan',
  component: FloorPlanModalComponent,
});
