import styled from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.$dark900};
  border: 3px solid ${({ theme }) => theme.colors.$dark700};
  box-shadow: 0 5px 0 ${({ theme }) => theme.colors.$dark800};
  border-radius: 20px;
  position: absolute;
  top: 80px;
  left: 20px;
  right: 20px;
  bottom: 20px;

  & iframe {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.$white};
  }

  ${media.md`
    max-width: 90vw;
    margin: 70px auto;
    border-radius: 20px;
    padding: 10px;
    
    & iframe {
      border-radius: 20px;
    }
  `}

  ${media.lg`
    max-width: 1200px;
    margin: 70px auto;
  `}
`;
