import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledWrapperCloseIcon } from './styles';
import { modalRegistry } from '../../../features/modal/providers/modal.registry';
import { ModalComponentContext } from '../../../features/modal/providers/modal-component.context';

export const ButtonClose = ({ onClick, languageMod, ...rest }) => {
  const navigate = useNavigate();
  const { modal } = useContext(ModalComponentContext);

  const handleClick = useCallback(() => {
    navigate(`/${window.location.hash}`);
    modalRegistry.get(modal?.id)?.close();
    onClick?.();
  }, [modal?.id, navigate, onClick]);

  return <StyledWrapperCloseIcon {...rest} onClick={handleClick} />;
};
