import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import PropTypes from 'prop-types';
import { StyledText, StyledWrapper, CloseNotification, WrapperNotification } from './styles';

export const Notification = ({
  notificationThumb,
  notificationMessage,
  notificationTitle,
  closeNotification,
  onClick,
  locationId,
}) => {
  const [closeAnimation, setCloseAnimation] = useState(false);

  const closeNotificationOnMobile = () => {
    setCloseAnimation(true);
    setTimeout(() => {
      closeNotification(locationId);
    }, 400);
  };

  const handlers = useSwipeable({
    onSwipedRight: () => closeNotificationOnMobile(),
  });

  return (
    <WrapperNotification>
      <StyledWrapper closeAnimation={closeAnimation} {...handlers} onClick={onClick}>
        <div>{notificationThumb && <img src={notificationThumb.filename} />}</div>
        <StyledText>
          <div>{notificationTitle}</div>
          <span>{notificationMessage}</span>
        </StyledText>
      </StyledWrapper>
      <CloseNotification onClick={() => closeNotification(locationId)}>x</CloseNotification>
    </WrapperNotification>
  );
};

Notification.propTypes = {
  locationId: PropTypes.string,
  notificationMessage: PropTypes.string,
  notificationThumb: PropTypes.shape({
    filename: PropTypes.string,
  }),
  notificationTitle: PropTypes.string,
};
