import styled, { css } from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.md(css`
    align-items: center;
    flex-direction: row;
    padding-top: 120px;
  `)}
`;

export const StyledStreaming = styled.div`
  width: 100%;

  ${media.md`
    width:50%;
    height: 100%;
  `}
`;

export const StyledFrame = styled.div`
  height: 100%;
  width: 100%;

  ${media.md`
    border-left: 1px solid ${({ theme }) => theme.colors.$dark700};
    width:50%;
    height: 100%;
    margin-left: 20px;
    padding: 0 0 20px 0;
  `}

  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.$dark700};
    padding: 0 0 16px 20px;
  }

  iframe {
    width: 100%;
    height: calc(100% - 3px);

    ${media.md`
    height: calc(100% - 60px);
    padding-left: 20px;
    `}
  }
`;
export const StyledIcons = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.$primary200};
  position: relative;
  background-color: ${({ theme }) => theme.colors.$primary700};

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
  }
`;
