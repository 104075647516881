import styled, { css } from 'styled-components';
import { media } from '../../mixins/media';

export const StyledWrapperCloseIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.$primary900};
  border: 2px solid ${({ theme }) => theme.colors.$primary200};
  cursor: pointer;
  position: absolute;
  z-index: 99999;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 3px 0 0 ${({ theme }) => theme.colors.$primary900};
  user-select: none;

  ${media.lg(css`
    position: fixed;
    width: 56px;
    height: 56px;
    top: 40px;
    right: 40px;
    border-radius: 16px;
  `)}
`;
