import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCount, StyledInout, StyledName, StyledTag, StyledTags, StyledTitle, StyledWrapper } from './styles';
import { FlexBox } from '../../../../ui/atoms/flex-box';
import { Button } from '../../../../ui/atoms/button';
import { useAddMessage, useMessagesLoading } from '../../hooks/use-live-stream-messages';
import { Typography } from '../../../../ui/atoms/typography';
import { useScreenDetect } from '../../../../hocs/use-screen-detect';
import { Spacing } from '../../../../ui/atoms/spacing';

export const LiveStreamDetailsContainer = ({ id }) => {
  const loading = useMessagesLoading();
  const [message, setMessage] = useState('');
  const { t } = useTranslation(`livestream-twitter/${id}`);
  const addMessage = useAddMessage();
  const { isMobile } = useScreenDetect();

  const tagsList = t('content.twitterRecommendedHashtags').split(',');

  const onSubmit = useCallback(() => {
    addMessage({
      id: Date.now(),
      text: message,
      createdAt: 'now',
      author: 'Nicolas Marullo',
      nickName: '@cincoagency ',
      avatar: '/icons/avatar-xspace.svg',
      link: 'https://wearecinco.com/',
    });
    setMessage('');
  }, [addMessage, message]);

  return (
    <StyledWrapper>
      <StyledTitle alignItems="center">
        <img src="/icons/avatar-xspace.svg" />
        <StyledName medium size="bodyM">
          <img src="/icons/twitter.svg" width={12} />
          Nicolas Marullo
          <span>@cincoagency</span>
        </StyledName>
        <StyledCount medium size={isMobile ? 'bodyS' : 'bodyM'}>
          {message.length}
          /140
        </StyledCount>
      </StyledTitle>
      <StyledInout
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Say some cool..."
        maxLength={140}
        stretch
      />
      <FlexBox alignItems="center" justifyContent="space-between">
        <StyledTags>
          <div>
            {!isMobile ? (
              <>
                <Typography size="bodyM" tag="div">
                  Hashtags recommendation
                </Typography>
                <Spacing pt="8px" />
              </>
            ) : (
              ''
            )}
            {tagsList.map((tag) => (
              <StyledTag key={tag}>#{tag}</StyledTag>
            ))}
          </div>
        </StyledTags>
        <Button onClick={onSubmit} disabled={loading}>
          <span>Tweet</span>
          <img src="/icons/twitter.svg" width={16} />
        </Button>
      </FlexBox>
    </StyledWrapper>
  );
};
