import styled from 'styled-components';
import { media } from '../../../ui/mixins/media';

export const Container = styled.div`
  padding-top: 22px;
  height: 90vh;
  width: 100%;

  ${media.md`
    padding-top: 60px;
  `}
`;

export const HotWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: color 0.2s;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
  }

  & div:hover {
    color: #${({ theme }) => theme.colors.$info};
  }

  & div:hover::before {
    opacity: 1;
  }

  & div::before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(100%, -50%);
    right: -28px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 16px;
    height: 16px;
    z-index: 4;
    transition: opacity 0.2s;
    background-image: url('/icons/vector.svg');

    ${media.md`
    width: 20px;
    height: 20px;
    right: -30px;
    `}
  }
`;
