import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledTypography } from './typography.styles';

export const Typography = forwardRef(
  (
    {
      children,
      center,
      variant = 'text',
      tag = 'span',
      size = 'small2',
      extraBold,
      semiBold,
      bold,
      medium,
      uppercase,
      capitalize,
      breakSpaces,
      color,
      altTitle,
      overflowLines,
      ...rest
    },
    ref,
  ) => (
    <StyledTypography
      {...rest}
      ref={ref}
      center={center}
      as={tag}
      color={color}
      overflowLines={overflowLines}
      variant={variant}
      size={size}
      bold={bold}
      semiBold={semiBold}
      extraBold={extraBold}
      medium={medium}
      uppercase={uppercase}
      capitalize={capitalize}
      breakSpaces={breakSpaces}
      title={altTitle}
    >
      {children}
    </StyledTypography>
  ),
);

Typography.displayName = 'Typography';

Typography.propTypes = {
  variant: PropTypes.oneOf(['title', 'text']),
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'bodyL', 'bodyM', 'bodyS']),
  semiBold: PropTypes.bool,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  medium: PropTypes.bool,
  overflowLines: PropTypes.number,
  extraBold: PropTypes.bool,
};
