import { createSlice } from '@reduxjs/toolkit';
import { postMessageThunk } from './live-stream.thunk';

const initialState = {
  loading: false,
  messages: [],
};

export const liveStreamSlice = createSlice({
  name: 'live-stream',
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders.addCase(postMessageThunk.pending, (state) => {
      state.loading = true;
    });
    builders.addCase(postMessageThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.messages.unshift(action.payload);
    });
  },
});

export const liveStreamSliceActions = liveStreamSlice.actions;

export default liveStreamSlice.reducer;
