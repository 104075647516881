import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMenuWrapper } from './styles';
import { useNavigation } from '../../../system/hooks/use-system-store';
import { MenuItem } from './menu-item';

export const Footer = () => {
  const navigation = useNavigation();
  const { i18n } = useTranslation();

  const items = useMemo(() => {
    const nav = navigation[i18n?.language];
    if (!nav) {
      return [];
    }
    const ar = Array.from({ length: 5 }).map((el, i) => i + 1);
    return ar
      .filter((id) => !nav[`navFooterPosition${id}Hidden`])
      .map((id) => ({
        id,
        name: nav[`navFooterPosition${id}Name`],
        eventId: nav[`navFooterPosition${id}EventId`],
        icon: nav[`navFooterPosition${id}Icon`]?.filename,
      }));
  }, [navigation, i18n?.language]);

  return (
    <StyledMenuWrapper>
      {items.map((item) => (
        <MenuItem key={item.name} {...item} />
      ))}
    </StyledMenuWrapper>
  );
};
