import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import {
  StyledWrapper,
  LoadingImageStub,
  StyledLoadingContent,
  StyledPreviewImage,
  StyledPreviewWrapper,
  FloorPreviewText,
} from './styles';
import { Button } from '../../../../ui/atoms/button';
import { useNavigate3DV } from '../../../3dvista/hooks/use-3dvista-actions';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { Spacing } from '../../../../ui/atoms/spacing';
import { Typography } from '../../../../ui/atoms/typography';
import { useDispatch3dvistaNavigationEvent } from '../../../../hooks/use-dispatch-3dvista-navigation-event';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';

export const LocationPinPreview = ({ featureId, buttonTitle, data, isLoading }) => {
  const navigate3DV = useNavigate3DV();
  const dispatchEvent = useDispatch3dvistaNavigationEvent();
  const dispatchCompleteEvent = useDispatchCompleteEvent();
  const theme = useTheme();

  const onDiscoverClick = useCallback(() => {
    if (data.systemName) {
      navigate3DV({ systemName: data.systemName, nav3dv: data.nav3dv });
    } else if (data.eventId) {
      dispatchEvent(data.eventId);
    }
    dispatchCompleteEvent({
      name: FEATURE_NAMES.floorPlan,
      id: featureId,
    });
    modalRegistry.get('FloorPlan')?.close();
  }, [data, dispatchCompleteEvent, dispatchEvent, featureId, navigate3DV]);

  const showLoading = isLoading || !data;

  return (
    <StyledWrapper>
      <StyledPreviewWrapper>
        {showLoading ? <LoadingImageStub /> : <StyledPreviewImage src={data.previewImage?.filename} />}
        {!showLoading ? (
          <Spacing mt="14px">
            <FloorPreviewText size="h4" bold color={theme.colors.$white} uppercase>
              {data.name}
            </FloorPreviewText>
          </Spacing>
        ) : (
          <StyledLoadingContent>
            <div />
          </StyledLoadingContent>
        )}
        {(data?.eventId || data?.systemName || data?.nav3dv) && (
          <Spacing mt="24px">
            <Button onClick={onDiscoverClick}>
              <Typography size="bodyL" color={theme.colors.$white} bold>
                {buttonTitle}
              </Typography>
              <img src="/icons/rocket.svg" alt="icon" />
            </Button>
          </Spacing>
        )}
      </StyledPreviewWrapper>
    </StyledWrapper>
  );
};

LocationPinPreview.propTypes = {
  isLoading: PropTypes.bool,
  buttonTitle: PropTypes.string,
  data: PropTypes.shape({
    systemName: PropTypes.string,
    title: PropTypes.string,
    eventId: PropTypes.string,
    description: PropTypes.string,
    previewImage: PropTypes.shape({
      filename: PropTypes.string,
    }),
    previewImageRetina: PropTypes.string,
    x: PropTypes.string,
    y: PropTypes.string,
    nav3dv: PropTypes.shape({
      panoName: PropTypes.string,
      modelName: PropTypes.string,
      viewName: PropTypes.string,
    }),
  }),
};
