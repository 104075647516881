import styled from 'styled-components';
import { Typography } from '../../../../ui/atoms/typography';
import { FlexBox } from '../../../../ui/atoms/flex-box';
import { media } from '../../../../ui/mixins/media';

export const StyledTitle = styled(Typography)`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.$white};
  margin: 0 0 24px;
  display: block !important;
  text-align: center;

  ${media.sm`
    text-align: left;
  `}
`;

export const StyledAgreement = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.$white};
  font-size: 10px;
  line-height: 18px;
  margin-top: 15px;

  & > label {
    margin-right: 15px;
  }

  & a {
    color: #60a5fa;
  }

  ${media.sm`
    margin-top: 20px;
  `}
`;

export const StyledContent = styled.div`
  padding: 20px;

  ${media.sm`
    display: flex;
  `}
`;

export const StyledSuccess = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding: 20px 20px 40px;

  ${media.md`
    font-size: 32px;
    line-height: 48px;
    padding: 50px 130px 110px;
  `}
`;

export const LeftBar = styled.div`
  flex: 1;

  ${media.sm`
    margin-left: 20px;
  `}
`;

export const RightBar = styled.div`
  position: relative;
  flex: 0 0 240px;
  margin-top: 20px;

  ${media.sm`
    margin-top: 0;
    margin-left: 20px;
  `}

  & button {
    position: absolute;
    z-index: 2;
    top: 37%;
    left: 0;
    right: 0;
    margin: auto;

    ${media.sm`
     top: 115px;
    `}
  }

  & .media-wrapper {
    ${media.sm`
      &::after {
          padding-top: 177.25%;
        }
      }
  `}
  }
`;

export const StyledError = styled(FlexBox)`
  margin: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #cb444a;

  img {
    margin-right: 6px;
  }
`;
