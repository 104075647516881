import React, { useCallback } from 'react';
import { Spacing } from '../../../../ui/atoms/spacing';
import { Typography } from '../../../../ui/atoms/typography';
import { StyledMenuWrapper, MenuItem } from './styled';
import { useScreenDetect } from '../../../../hocs/use-screen-detect';
import { useHandleEvent } from '../../../app/hooks/use-handle-event';

export const MenuItems = ({ list, onClose }) => {
  const { isMobile } = useScreenDetect();
  const handleEvent = useHandleEvent();

  const onEventClick = useCallback(
    (eventId) => {
      handleEvent(eventId);
      onClose();
    },
    [handleEvent, onClose],
  );

  return (
    <>
      <Spacing mb={isMobile ? '20px' : '44px'} />
      <StyledMenuWrapper>
        {list.map((item) => (
          <MenuItem
            key={item.name}
            onClick={() => onEventClick(item.eventId)}
            image={item.previewImage?.filename}
            type={item.type}
          >
            {!!item.icon?.filename && <img src={item.icon?.filename} />}
            <Typography tag="div" size={isMobile ? 'bodyL' : 'h6'} medium={isMobile} semiBold={!isMobile}>
              {item.name}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenuWrapper>
    </>
  );
};
