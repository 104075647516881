import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../ui/atoms/typography';
import { StaticPageLayout } from '../../ui/layouts/static-page-layout';

export const TermsPage = () => {
  const { t } = useTranslation('terms');

  return (
    <StaticPageLayout>
      <Typography tag="div" variant="title" size="heading3" semiBold center uppercase>
        <div dangerouslySetInnerHTML={{ __html: t('title') }} />
      </Typography>
      <Typography tag="p">
        <div dangerouslySetInnerHTML={{ __html: t('p1') }} />
      </Typography>
      <Typography tag="p">
        <div dangerouslySetInnerHTML={{ __html: t('p2') }} />
      </Typography>
    </StaticPageLayout>
  );
};
