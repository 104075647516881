import { defaultTheme } from '../../ui/config/default-theme';

export function ThemeModel(data = {}) {
  this.fonts = { ...defaultTheme.fonts };
  this.colors = { ...defaultTheme.colors };
  this.zIndex = { ...defaultTheme.zIndex };
  this.animations = { ...defaultTheme.animations };

  const { colors } = data;

  if (colors) {
    Object.keys(colors).forEach((key) => {
      if (key in this.colors) {
        this.colors[key] = colors[key];
      }
    });
  }
}
