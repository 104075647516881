import { useCallback } from 'react';
import { EventAPI } from '../../../api/event';
import { useAddEventsToQueue } from '../../system/hooks/use-system-store';
import { usePerformEvent } from './use-perform-event';

export const useHandleEvent = () => {
  const performEvent = usePerformEvent();
  const addEventsToQueue = useAddEventsToQueue();

  const getNavigationEvents = useCallback(async (eventId) => {
    const { data } = await EventAPI.getEventDetails({
      type: 'navigation',
      eventId,
    });
    return Object.values(data?.events ?? []) ?? [];
  }, []);

  return useCallback(
    async (eventId) => {
      try {
        const events = await getNavigationEvents(eventId);
        const firstEvent = events.shift();
        if (events.length) {
          addEventsToQueue(events);
        }
        performEvent(firstEvent);
      } catch (err) {
        console.error("Events doesn't exist", err);
      }
    },
    [addEventsToQueue, getNavigationEvents, performEvent],
  );
};
