import React from 'react';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { LiveStreamArenaContainer } from '../../containers/live-stream-arena-container';
import { StyledLayout } from './styles';

const ModalComponent = ({ closeModal, id }) => (
  <StyledLayout onClose={closeModal}>
    <LiveStreamArenaContainer id={id} />
  </StyledLayout>
);

export const LiveStreamArenaModal = modalRegistry.register({
  id: 'Arena',
  component: ModalComponent,
});
