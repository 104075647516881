import React from 'react';
import PropTypes from 'prop-types';
import { StyledSvg } from './svg-icon.styles';

export const SvgIcon = (props) => {
  const { width = 18, height, color, activeColor, icon } = props;

  return (
    <StyledSvg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      activeColor={activeColor}
      width={width}
      height={height ?? width}
    >
      <use xlinkHref={`/icons/icons.svg#${icon}`} />
    </StyledSvg>
  );
};

SvgIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  activeColor: PropTypes.string,
  icon: PropTypes.oneOf([
    'paper-plain',
    'arrowLeft',
    'twitter',
    'systemApps',
    'close',
    'play',
    'eye',
    'downLeft',
    'downLeft',
    'media-pause',
    'media-play',
    'media-muted',
    'media-unmuted',
    'cc-enabled',
    'cc-disabled',
  ]).isRequired,
};
