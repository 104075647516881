import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntroTourStoreApi } from './use-intro-tour-store-api';
import { LocationAPI } from '../../../api/location';
import { useNavigate3DV } from '../../3dvista/hooks/use-3dvista-actions';

export const useFetchNotifications = () => {
  const { setNotifications } = useIntroTourStoreApi();
  const { i18n } = useTranslation();
  const navigate3DV = useNavigate3DV();

  return useCallback(
    async ({ id }) => {
      const res = await LocationAPI.fetchById(id);
      const notification = res.data.content[i18n?.language];
      if (notification) {
        setNotifications([{ ...notification, locationId: id }]);
      }
      // Loads a given 3D Vista navigation event at same time as displaying notification
      const nav3dv = notification.nav3dv?.[0];
      if (nav3dv?.modelName || nav3dv?.panoName || notification.systemName) {
        navigate3DV({
          systemName: notification.systemName,
          nav3dv,
        });
      }
    },
    [navigate3DV, i18n?.language, setNotifications],
  );
};
