import styled from 'styled-components';
import { FullscreenModalLayout } from '../../../../ui/layouts/fullscreen-modal-layout';
import { media } from '../../../../ui/mixins/media';

export const StyledLayout = styled(FullscreenModalLayout)`
  padding: 0;

  ${media.md`
    padding: 0 40px;
  `}
`;
