import { useCallback, useEffect } from 'react';
import log from 'loglevel';
import { useHandleEvent } from '../../../app/hooks/use-handle-event';
import { EVENT_COMPLETED, FEATURE_NAMES } from '../../../../config/features.config';
import { useCompleteGame } from '../../../game/hooks/use-earned-points';
import { useCheckEventsQueue } from '../../../system/hooks/use-check-events-queue';
import { useSendJitsuEvent } from '../../../app/hooks/use-send-jitsu-event';

export const EventManagerContainer = () => {
  const handleEvent = useHandleEvent();
  const complete = useCompleteGame();
  const checkEventsQueue = useCheckEventsQueue();
  const sendJitsuEvent = useSendJitsuEvent();

  const handle3DVEvent = useCallback(
    async (event) => {
      const { detail } = event;
      if (!detail) {
        log.warn('[3DV] Event detail is not provided');
        return;
      }

      const { type, eventId } = detail;
      if (type !== 'navigation' && !eventId) {
        log.debug('[3DV] Event type is not navigation or eventId is missing');
        return;
      }
      log.info('[3DV] Handling 3DV event', eventId);
      handleEvent(eventId);
    },
    [handleEvent],
  );

  const handleCompletedEvent = useCallback(
    async (event) => {
      const { id, name, payload } = event.detail || {};
      if (Object.values(FEATURE_NAMES).includes(name)) {
        log.debug(`[3DV] Completing event for feature ${name}`);
        complete({
          name,
          id,
          payload,
        });
        sendJitsuEvent('Navigation > Close XP', { xpId: id, xpType: name });
      } else {
        log.warn(`[3DV] Feature name ${name} is not recognized`);
      }
      checkEventsQueue();
    },
    [checkEventsQueue, complete, sendJitsuEvent],
  );

  useEffect(() => {
    // log.debug('[3DV] Adding event listener for 3dvista');
    document.addEventListener('3dvista', handle3DVEvent);

    return () => {
      // log.debug('[3DV] Removing event listener for 3dvista');
      document.removeEventListener('3dvista', handle3DVEvent);
    };
  }, [handle3DVEvent, handleEvent]);

  useEffect(() => {
    // log.debug(`[3DV] Adding event listener for ${EVENT_COMPLETED}`);
    document.addEventListener(EVENT_COMPLETED, handleCompletedEvent);

    return () => {
      // log.debug(`[3DV] Removing event listener for ${EVENT_COMPLETED}`);
      document.removeEventListener(EVENT_COMPLETED, handleCompletedEvent);
    };
  }, [handleCompletedEvent]);

  return null;
};
