import styled, { css } from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  ${media.md`
  max-width: 1124px;
  `}
`;

export const MenuItem = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
  margin: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  border: 3px solid ${({ theme }) => theme.colors.$dark700};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  @media (max-width: 400px) {
    width: 150px;
    height: 150px;
    margin: 10px;
  }

  & div {
    text-align: center;
  }

  & img {
    height: 50px;
    width: 50px;
    margin-bottom: 25px;

    ${media.md`
      height: 60px;
      width: 60px;
    `}
  }

  ${media.md`
    width: 200px;
    height: 200px;
    padding: 30px;
  `}

  ${(props) =>
    props.type === 'basic-secondary' &&
    css`
      background: linear-gradient(rgba(255, 225, 167, 1), rgba(237, 128, 0, 1));
    `}

  ${(props) =>
    props.type === 'image-basic' &&
    css`
      background-image: url('${props.image}');
    `}
  
  ${(props) =>
    props.type === 'image-overlay' &&
    css`
      background-image: url('${props.image}');

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(39, 41, 51, 0.4);
      }

      & div {
        position: relative;
        z-index: 2;
      }
    `}

  ${(props) =>
    props.type === 'basic-primary' &&
    css`
      background: linear-gradient(rgba(57, 134, 208, 1), rgba(0, 73, 144, 1));
    `}
`;
