import React from 'react';
import { StyledWrapper } from './styles';
import { FullscreenModalLayout } from '../fullscreen-modal-layout';

export const StaticPageLayout = ({ children }) => (
  <FullscreenModalLayout onClose={console.log}>
    <StyledWrapper>
      <div>{children}</div>
    </StyledWrapper>
  </FullscreenModalLayout>
);
