import { FONT_SIZE } from './typography.constants';

export const getFontWeight = ({ bold, extraBold, semiBold, medium }) => {
  const weight = { fontWeight: 400 };
  if (extraBold) {
    weight.fontWeight = 800;
    return weight;
  }
  if (bold) {
    weight.fontWeight = 700;
    return weight;
  }
  if (semiBold) {
    weight.fontWeight = 600;
    return weight;
  }
  if (medium) {
    weight.fontWeight = 500;
    return weight;
  }

  return weight;
};

export const getFontSize = (size) => (size ? FONT_SIZE[size] : FONT_SIZE.small2);
