import styled from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledWrapper = styled.div`
  padding: 8px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.$dark800};
  border-radius: 10px;
  margin: 20px 10px 0 10px;

  ${media.md`
  margin: 20px 0 0 20px;
  `}

  & > div {
    flex: 1;
  }

  & > div:first-child {
    flex: 1;
    width: unset;
  }

  & > div + div {
    margin-left: 16px;
  }

  ${media.md`
    & > div:first-child {
      flex: 0 0 260px;
      width: 260px;
    }
  `}
`;
