import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledLogo } from './styles';
import { RouteE } from '../../../../config/navigation.config';
import { useProject } from '../../../system/hooks/use-system-store';

export const Logo = () => {
  const { content } = useProject();
  const { i18n } = useTranslation();

  const { brandLogo } = content[i18n.language] || {};

  if (!brandLogo) {
    return null;
  }

  return (
    <Link to={RouteE.home}>
      <StyledLogo>
        <img src={brandLogo.filename} alt={brandLogo.alt} title={brandLogo.title} />
      </StyledLogo>
    </Link>
  );
};
