import React from 'react';
import { StyledPlayError } from './styles';

export const VideoPlayError = ({ onPlay }) => (
  <StyledPlayError>
    <div onClick={onPlay}>
      <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* eslint-disable max-len */}
        <path
          d="M24.4999 4.08301C13.2422 4.08301 4.08325 13.2419 4.08325 24.4997C4.08325 35.7574 13.2422 44.9163 24.4999 44.9163C35.7577 44.9163 44.9166 35.7574 44.9166 24.4997C44.9166 13.2419 35.7577 4.08301 24.4999 4.08301ZM24.4999 40.833C15.4941 40.833 8.16659 33.5055 8.16659 24.4997C8.16659 15.4939 15.4941 8.16634 24.4999 8.16634C33.5057 8.16634 40.8333 15.4939 40.8333 24.4997C40.8333 33.5055 33.5057 40.833 24.4999 40.833Z"
          fill="white"
        />
        {/* eslint-enable max-len */}
        <path d="M18.375 34.7087L34.7083 24.5003L18.375 14.292V34.7087Z" fill="white" />
      </svg>
    </div>
  </StyledPlayError>
);
