import { useContext } from 'react';
import { JitsuContext } from '@jitsu/react';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';
import { PROJECT_ID } from '../../../config/common.config';
import { useProject } from '../../system/hooks/use-system-store';

export const useSendJitsuEvent = () => {
  const jitsuContextValue = useContext(JitsuContext);
  const { i18n } = useTranslation();
  const project = useProject();

  return (name, payload = {}) => {
    if (!project.configuration?.cookiebotDomainEnabled || window.Cookiebot.consent.statistics) {
      log.debug(`[Jitsu] Send event started for "${name} (${payload.xpType})"`);
      jitsuContextValue?.track(`${name} (${payload.xpType})`, {
        ...payload,
        projectId: PROJECT_ID,
        prefLocale: i18n.language,
      });
      log.debug(`[Jitsu] Send event completed for "${name} (${payload.xpType})"`);
    } else {
      log.debug('[Jitsu] Event not sent due to lack of consent or Cookiebot being disabled');
    }
  };
};
