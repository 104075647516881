import { useCallback } from 'react';
import log from 'loglevel';

/**
 * useNavigate3DV is a hook that handles navigation within 3D Vista projects.
 * It can navigate to both panoramas and 3D models based on the provided parameters.
 *
 * @param {Object} params - The parameters for navigation.
 * @param {string} params.systemName - The system name of the panorama to navigate to. This has the highest priority.
 * @param {Object} params.nav3dv - The navigation parameters for 3D Vista.
 * @param {string} params.nav3dv.panoName - The name of the pano to navigate to. This has the second highest priority.
 * @param {string} params.nav3dv.modelName - The name of the 3D model to navigate to.
 * @param {string} params.nav3dv.viewName - The view name of the 3D model to navigate to.
 *
 * @returns {Function} The function to call to perform the navigation.
 */
export const useNavigate3DV = () =>
  useCallback(({ systemName, nav3dv }) => {
    log.debug('[3DV] useNavigate3DV: Start processing 3D Vista navigation', { systemName, nav3dv });
    if (systemName?.trim()) {
      log.debug('[3DV] useNavigate3DV: Navigating to panorama using systemName', systemName);
      window.blazeIT?.navigateToPanorama?.(systemName);
    } else if (nav3dv?.panoName) {
      log.debug('[3DV] useNavigate3DV: Navigating to panorama using panoName', nav3dv.panoName);
      window.blazeIT?.navigateToPanorama?.(nav3dv.panoName);
    } else if (nav3dv?.modelName) {
      log.debug('[3DV] useNavigate3DV: Opening 3D model', { modelName: nav3dv.modelName, viewName: nav3dv.viewName });
      window.blazeIT_no3d?.open3DModel?.(nav3dv.modelName, nav3dv.viewName || '');
    }
    log.debug('[3DV] useNavigate3DV: 3D Vista navigation completed');
  }, []);
