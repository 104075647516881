import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyledImages, StyledTitle } from './styles';

export const TweeterAdv = ({ id }) => {
  const { t } = useTranslation(`livestream-twitter/${id}`);

  return (
    <div>
      <StyledTitle>
        <Trans i18nKey="join.youCanBeTagged">{t('content.promoHeadingSecondary')}</Trans>
      </StyledTitle>
      <StyledImages>
        <img src={t('content.promoTweet1Image.filename')} />
        <img src={t('content.promoTweet2Image.filename')} />
      </StyledImages>
    </div>
  );
};
