import { createSlice } from '@reduxjs/toolkit';
import { STORAGE_KEYS } from '../../../config/common.config';

const initialState = {
  loggedIn: !!localStorage.getItem(STORAGE_KEYS.loggedIn),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state, { payload }) => {
      state.loggedIn = payload;
    },
  },
});

export const authSliceActions = authSlice.actions;

export default authSlice.reducer;
