import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAdvList, StyledTitle, StyledWrapper } from './styles';
import { FlexBox } from '../../../../ui/atoms/flex-box';
import { Button } from '../../../../ui/atoms/button';
import { SvgIcon } from '../../../../ui/atoms/svg-icon';
import { useScreenDetect } from '../../../../hocs/use-screen-detect';

export const JoinToConversation = ({ onJoin, id }) => {
  const { t } = useTranslation(`livestream-twitter/${id}`);
  const { isMobile } = useScreenDetect();

  return (
    <StyledWrapper>
      <StyledTitle>{t('content.promoHeadingPrimary')}</StyledTitle>
      <StyledAdvList>
        <FlexBox alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'}>
          <span dangerouslySetInnerHTML={{ __html: t('content.promoHighlights') }} />
        </FlexBox>
      </StyledAdvList>
      <Button variant="white" onClick={onJoin}>
        <SvgIcon icon="twitter" width={20} />
        <span>{t('content.twitterSignInButtonText')}</span>
      </Button>
    </StyledWrapper>
  );
};
