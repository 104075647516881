import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { store, persistor } from './store';
import { parseUtm } from './utils/parse-utm';
import { manageCacheAndVersion } from './utils/cacheAndVersion';
import './index.css';

parseUtm();
manageCacheAndVersion();

// eslint-disable-next-line import/first
import './config/i18next.config';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);
