import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styles';
import { FlexBox } from '../flex-box';

export const Button = ({ children, ...rest }) => (
  <StyledButton {...rest}>
    <FlexBox alignItems="center">{children}</FlexBox>
  </StyledButton>
);

Button.propTypes = {
  variant: PropTypes.oneOf(['white']),
};
