import { usePageView } from '@jitsu/react';
import { useTranslation } from 'react-i18next';
import { PROJECT_ID } from '../../../../config/common.config';

export const JitsuPageView = () => {
  const { i18n } = useTranslation();

  usePageView({
    payload: {
      projectId: PROJECT_ID,
      prefLocale: i18n?.language,
    },
  });

  return null;
};
