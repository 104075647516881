import React from 'react';
import { StyledWrapper } from './styles';
import { JoinToConversation } from '../../components/join-to-conversation';
import { TweeterAdv } from '../../components/tweeter-adv';
import { useScreenDetect } from '../../../../hocs/use-screen-detect';

export const LiveStreamSignInContainer = ({ onSignIn, id }) => {
  const { isMobile } = useScreenDetect();

  return (
    <StyledWrapper>
      <JoinToConversation id={id} onJoin={onSignIn} />
      {!isMobile && <TweeterAdv id={id} />}
    </StyledWrapper>
  );
};
