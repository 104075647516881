import { apiClient } from '../api-client';
import { PROJECT_ID } from '../../config/common.config';

export class ProjectAPI {
  static getInfo() {
    return apiClient.get(`/projects/${PROJECT_ID}/`);
  }

  /**
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getNavigation(id) {
    return apiClient.get(`/xps/${PROJECT_ID}/navigation/${id}`);
  }
}
