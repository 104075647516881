export function ProjectModel(data = {}) {
  this.content = {
    en: {
      brandLogo: {
        alt: '',
        filename: '',
        name: '',
        title: '',
      },
      metaDescription: '',
      metaKeywords: '',
      metaTitle: '',
      ogTitle: '',
      ogDescription: '',
      ogImage: {
        alt: '',
        filename: '',
        name: '',
        title: '',
      },
    },
  };

  this.projectName = '';

  this.configuration = {
    navigationId: 0,
    entryNavEventId: 0,
    jaHostname: '',
    jaKey: '',
  };

  this.global = {
    locales: { default: 'English' },
  };

  const { configuration, content, global, projectName } = data;

  this.configuration.jaTrackPageviews = configuration?.jaTrackPageviews;
  this.configuration.displayHeaderLogo = configuration?.displayHeaderLogo;
  this.configuration.gaMeasurementId = configuration?.gaMeasurementId;
  this.configuration.navigationId = configuration?.navigationId;
  this.configuration.jaHostname = configuration?.jaHostname;
  this.configuration.jaKey = configuration?.jaKey;
  this.configuration.cookiebotDomainGroupId = configuration?.cookiebotDomainGroupId;
  this.configuration.cookiebotDomainEnabled = configuration?.cookiebotDomainEnabled;
  this.configuration.entryNavEventId = configuration?.entryNavEventId;
  this.projectName = projectName ?? '';

  if (content) {
    this.content = content;
  }
  if (global) {
    this.global = global;
  }
}
